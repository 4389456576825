import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'

const BlogPost = ({ pageContext, data, location }) => {
  const {
    markdownRemark: post,
  } = data
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout location={location}>
      <Helmet title={`Blog - ${post.frontmatter.title}`} />
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" / "
        crumbLabel={post.frontmatter.title}
      />
      <article key={post.id}>
        <div className="blog-post-details">
          <h1>{post.frontmatter.title}</h1>
          <span className="date badge">
            {post.frontmatter.date}
          </span>
          <GatsbyImage image={getImage(post.frontmatter.featuredImage)} alt="post content appropriate preview"/>
        </div>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBypath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default BlogPost